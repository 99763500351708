// app/javascript/controllers/payment-processing/review.js
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-processing--review"

export default class extends Controller {

    connect() {
        function waitOnStatusUpdate() {
            listenStatusUpdates();
            return setTimeout((function() {
                return updateStatus('processing');
            }), 3000);
        }
        function listenStatusUpdates() {
            let customerGuid, leaseNumber;
            leaseNumber = $('#credit_card_processing_payment').data('lease_number');
            customerGuid = $('#credit_card_processing_payment').data('customer_guid');
            return window.PusherClient.subscribePaymentStatusUpdates(customerGuid, leaseNumber, handleStatusUpdateEvent, handleStatusUpdateNotifierError);
        }
        function handleStatusUpdateEvent(_, arg) {
            let expectedPaymentReferenceCode, payment_reference_code, status;
            payment_reference_code = arg.payment_reference_code, status = arg.status;
            expectedPaymentReferenceCode = $('#credit_card_processing_payment').data('payment_reference_code');
            if (expectedPaymentReferenceCode === payment_reference_code && status !== 'processing') {
                return updateStatus(status);
            }
        }
        function handleStatusUpdateNotifierError(_status) {
            return updateStatus('processing');
        }
        function updateStatus(status) {
            $('#credit_card_processing_payment input[name="status"]').val(status);
            return $('#credit_card_processing_payment form#payment_status_update_form').submit();
        }
        if ($('#credit_card_processing_payment').data('lease_number') && $('#credit_card_processing_payment').data('payment_reference_code')) {
            $('.loader-overlay').addClass('d-block');
            return waitOnStatusUpdate();
        } else {
            return window.PusherClient.disconnect();
        }
    }
}
