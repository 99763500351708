// Required for adding polyfills for IE 11
import '@hotwired/turbo-rails'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../controllers'
import './pusher_client'
import './error_notification_service'
import './payment_methods/failure_event'
import '../../../vendor/assets/javascripts/pusher.min'
import jquery from 'jquery'

import { I18n } from 'i18n-js';

window.i18n ||= new I18n();

const params = new URLSearchParams(window.location.search);

window.i18n.locale = params.get('lang') || 'en';
window.$ = jquery
