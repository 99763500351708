import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinner"
export default class extends Controller {
    static targets = ['spinner']

    connect() {
        this.hideLoading()
    }

    navigate() {
        this.showLoading()
    }

    disconnect() {
        super.disconnect();
    }

    showLoading() {
        this.spinnerTarget.classList.add('d-block')
    }

    hideLoading() {
        this.spinnerTarget.classList.remove('d-block')
    }
}
