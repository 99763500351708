import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["validStates"]
    static values = {
        customerState: String
    }

    connect() {
        this.modalElement = document.getElementById('confirmationModal')
        this.setupFormValidation()
        $('.loader-overlay').removeClass('d-block');
    }

    openModal(event) {
        event.preventDefault()
        this.checkValidation()
        jQuery(this.modalElement).modal('show')
    }

    checkValidation() {
        const isValid = this.validateAction()

        const validContent = document.getElementById('valid-content')
        const invalidContent = document.getElementById('invalid-content')

        if (isValid) {
            validContent.style.display = 'block'
            invalidContent.style.display = 'none'
        } else {
            validContent.style.display = 'none'
            invalidContent.style.display = 'block'
        }
    }

    setupFormValidation() {
        const form = document.getElementById('deletion-form')
        if (form) {
            form.addEventListener('submit', (event) => {
                if (!this.validateAction()) {
                    event.preventDefault()
                    event.stopPropagation()

                    const invalidContent = document.getElementById('invalid-content')
                    const validContent = document.getElementById('valid-content')
                    invalidContent.style.display = 'block'
                    validContent.style.display = 'none'
                }
            })
        }
    }

    validateAction() {
        const customerState = this.customerStateValue
        return this.validStatesTarget.value.includes(customerState)
    }
}