import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        $('.loader-overlay').removeClass('d-block');
        this.modalElement = document.getElementById('informationModal')
    }

    changeLease(e) {
        let query = '?lease_guid=' + e.target.selectedOptions[0].value
        window.location.href = window.location.pathname + query
    }

    openModal(event) {
        event.preventDefault()
        jQuery(this.modalElement).modal('show')
    }
}