// app/javascript/controllers/payment-processing/inputs.js
import { Controller } from "@hotwired/stimulus"
import JsBarcode from "jsbarcode";

// Connects to data-controller="barcode-display"
// JsBarcode init renders the barcode data into a barcode svg

export default class extends Controller {
    renderBarcode() {
        JsBarcode(".barcode").init();
    }
    connect() {
        // Listen for Turbo events to render the barcode
        document.addEventListener("turbo:load", this.renderBarcode)
    }

    disconnect() {
        document.removeEventListener("turbo:load", this.renderBarcode)
    }
}
