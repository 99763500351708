import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["frame", "container", "input", "preview", "error", "submit", "scrollButton"]
    static values = {
        name: String,
        threshold: { type: Number, default: 0.00015 },
        trustedOrigins: Array
    }

    connect() {
        this.hasReachedBottom = false
        this.hideScrollButton()
        if (!this.hasReachedBottom) {
            this.inputTarget.disabled = true
            this.inputTarget.focus()
            this.submitTarget.classList.add('disabled')
        }
        window.addEventListener('message', this.handleMessage.bind(this))
        this.scrollButtonTarget.addEventListener('click', this.scrollToSignature.bind(this))
    }

    disconnect() {
        window.removeEventListener('message', this.handleMessage.bind(this))
        this.scrollButtonTarget.removeEventListener('click', this.scrollToSignature.bind(this))
    }

    handleMessage(event) {
        const trustedOrigins = this.trustedOriginsValue.map(pattern => new RegExp(pattern));

        if (!trustedOrigins.some(regex => regex.test(event.origin))) {
            return;
        }

        let messageData = event.data
        if (typeof messageData === 'string') {
            try {
                messageData = JSON.parse(messageData)
                this.showScrollButton()
            } catch (e) {
                return
            }
        }

        if (messageData && messageData.type === 'ACIMA_NOTARY_PUBLIC_LEASE_AGREEMENT_SCROLLED_TO_BOTTOM') {
            this.hasReachedBottom = true
            this.endOfDocument()
        }
    }

    showScrollButton() {
        this.scrollButtonTarget.classList.remove('d-none')
    }

    hideScrollButton() {
        this.scrollButtonTarget.classList.add('d-none')
    }

    scrollToSignature() {
        this.inputTarget.scrollIntoView({ behavior: 'smooth' })
    }

    signatureScroll() {
        if (window.innerWidth <= 768) {
            window.scrollBy({ top: 285, behavior: 'smooth' });
        }
    }

    endOfDocument() {
        setTimeout(() => {
            if (this.hasInputTarget) {
                this.validateSignature()
                this.displayRules()
                this.inputTarget.disabled = false
            }
        }, 1)
        this.signatureScroll()
        this.showScrollButton()
    }

    // Handle signature validation on form
    displayRules() {
        if (this.hasRulesTarget) {
            const rulesHtml = `
        <div class="small mt-2">
          <div class="fw-bold mb-2">Validation Rules:</div>
          <div class="mb-1">
            <span class="me-2">✓</span>
            <span class="fw-medium">Must match: </span>
            <span class="font-monospace">${this.nameValue}</span>
          </div>
        </div>
      `
            this.rulesTarget.innerHTML = rulesHtml
        }
    }

    validateSignature() {
        const input = this.inputTarget.value
        let previewHtml = ''
        let isValid = true

        if (this.hasErrorTarget) {
            this.errorTarget.classList.add('d-none')
        }
        this.inputTarget.classList.remove('is-invalid')

        for (let i = 0; i < input.length; i++) {
            const char = input[i]
            const expectedChar = this.nameValue[i]
            const isCharValid = char === expectedChar

            if (!isCharValid) {
                isValid = false
            }

            previewHtml += `
        <span class="signature-char" 
              style="color: ${isCharValid ? 'black' : 'red'}"
              data-toggle="tooltip" 
              title="${isCharValid ? 'Correct' : expectedChar ? `Expected: ${expectedChar}` : 'Extra character'}">
          ${char}
        </span>`
        }

        if (input.length < this.nameValue.length) {
            const remainingChars = this.nameValue.slice(input.length)
            remainingChars.split('').forEach(char => {
                previewHtml += `
          <span class="signature-char missing" 
                data-toggle="tooltip"
                title="Missing/Wrong character">
            _
          </span>`
            })
            isValid = false
        }

        if (input.length > this.nameValue.length) {
            isValid = false
        }

        if (this.hasPreviewTarget) {
            this.previewTarget.innerHTML = previewHtml || ''

            const tooltips = this.previewTarget.querySelectorAll('[data-bs-toggle="tooltip"]')
            tooltips.forEach(el => new bootstrap.Tooltip(el))
        }

        if (!isValid) {
            if (this.hasErrorTarget) {
                this.errorTarget.classList.remove('d-none')
            }
            this.inputTarget.classList.add('is-invalid')
        }

        if (this.hasSubmitTarget) {
            this.submitTarget.disabled = !isValid
            this.submitTarget.classList.toggle('disabled', !isValid)
        }
    }

    validateForm(event) {
        if (!this.hasInputTarget) return

        const input = this.inputTarget.value
        const isValid = input === this.nameValue

        if (!isValid) {
            event.preventDefault()
            if (this.hasErrorTarget) {
                this.errorTarget.classList.remove('d-none')
            }
            this.inputTarget.classList.add('is-invalid')
        } else {
            $('.loader-overlay').addClass('d-block');
        }
    }
}