import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="transaction-history"
export default class extends Controller {
  static values = {
    guid: String,
  }

  async pull_payment_history() {
    const urlParams = new URLSearchParams(window.location.search)
    const getLanguage = urlParams.get('lang')

    let language = !getLanguage ? 'en' : getLanguage

    try {
      const response = await get(`/transaction_history?lang=${language}&lease_guid=${this.guidValue}`, {
        headers:
          {
            Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml, application/json, application/vnd.api+json'
          }
      })

      if (response.ok) {
        const contentType = response.headers.get("content-type")

        if (contentType && contentType.includes("application/json")) {
          return await response.json;
        } else if (contentType && contentType.includes("text/vnd.turbo-stream.html")) {
          return await response.text;
        }
      }
    } catch (error) {
      console.log('Failed Request.')
    }
  }
}
