import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="zeta"
export default class extends Controller {
    static targets = ["zetaBanner", "gpccBanner"]
    static values = {
        lightboxMarketingEnabled: Boolean,
        zetaEnv: Boolean
    }

    async connect() {
        // Feature flag value pulled in the html.
        try {
            if (this.lightboxMarketingEnabledValue) {
                await this.identifyUser()
                // wait for raccu endpoint then call zeta with gcid
                this.zetaScript()
            }
        } catch (error) {
            Rollbar.error("Error with lightbox:", error)
        }
    }

    async identifyUser() {
        try {
            const response = await get(`/raccu`, {
                headers: {'Content-Type': 'application/json'},
            })
            if (response.ok) {
                const jsonData = await response.json;
                const gcid = jsonData.gcid
                if (gcid) {
                    this.gcid = gcid
                    // show zeta banner
                    this.zetaBannerTarget.classList.remove('hidden')
                    this.gpccBannerTarget.classList.add('hidden')
                } else {
                    // show gpccbanner
                    this.gpccBannerTarget.classList.remove('hidden')
                    this.zetaBannerTarget.classList.add('hidden')
                }
            } else {
                // show gpccbanner
                this.gpccBannerTarget.classList.remove('hidden')
                this.zetaBannerTarget.classList.add('hidden')
            }

        } catch (e) {
            Rollbar.error(`Error with banner: ${e}`)
        }
    }

    zetaScript() {
        function zync_call() {
            var z = document.createElement("script");
            var zmpID = "rac-acima";
            var cache_buster = new Date().getTime();
            var z_src = "https://live.rezync.com/sync?c=16b6410431b6374e780104abb0443ca8&p=fc5f9a7700279787d93541209e170bcd&k=rac-acima-pixel-2353&zmpID=" + zmpID + "&cache_buster=" + cache_buster;
            z.setAttribute("src", z_src);
            document.body.appendChild(z);
        }

        function dev_zync_call() {
            var z = document.createElement("script");
            var zmpID = "rac-acima-dev";
            var cache_buster = new Date().getTime();
            var z_src = "https://live.rezync.com/sync?c=16b6410431b6374e780104abb0443ca8&p=fc5f9a7700279787d93541209e170bcd&k=rac-acima-pixel-2353&zmpID=" + zmpID + "&cache_buster=" + cache_buster;
            z.setAttribute("src", z_src);
            document.body.appendChild(z);
        }

        const gcid = this.gcid
        if (this.zetaEnvValue && gcid) {
            if (['complete', 'interactive'].indexOf(document.readyState) >= 0) {
                zync_call(gcid);
            } else {
                window.addEventListener("DOMContentLoaded", function () {
                    zync_call();
                });
            }
        } else if (gcid) {
            if (['complete', 'interactive'].indexOf(document.readyState) >= 0) {
                dev_zync_call(gcid);
            } else {
                window.addEventListener("DOMContentLoaded", function () {
                    dev_zync_call();
                });
            }
        }

        // Call this after zync_call() is executed, so bt lib can load
        let count = 0;

        function callBtFunction() {
            if (typeof bt === 'function') {
                // Tell zeta User logged in
                bt('updateUser', {user_id: gcid});
            } else {
                // bt not loaded yet, try again after a short delay
                if (count <= 10) {
                    count++;
                    setTimeout(callBtFunction, 100);
                }
            }
        }

        callBtFunction();
    }
}

