import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import JsBarcode from "jsbarcode"

// Connects to data-controller="payment-processing--cash_voucher_barcode"
export default class extends Controller {

    static targets = ["barcodeSpinner", "barcodeSVG", "barcodeBlock", "barcodeValue"]

    // This renderBarcode loads the svg/barcode with data
    connect() {
        this.params = new URLSearchParams(window.location.search)
        this.leaseGuid = this.params.get('lease_guid')
        this.getBarcodeCount = 0
        this.getBarcodeData()
    }

    async getBarcodeData() {
        this.getBarcodeCount += 1
        try {
            const response = await get('/cash_voucher', {
                query: { lease_guid: this.leaseGuid },
                responseKind: "json"
            })
            if (response.ok) {
                const contentType = response.headers.get("content-type")
                if (contentType && contentType.includes("application/json")) {
                    this.handleSuccess(await response.json)
                } else if (contentType && contentType.includes("text/vnd.turbo-stream.html")) {
                    this.handleError(await response.text)
                }
            } else {
                const error_text = await response.text
                this.handleError(error_text)
            }
        } catch (e) {
            this.hideSpinner()
            const barcode_error = i18n.t('payment_methods.cash_voucher.error.barcode_error')
            document.querySelector('flash').insertAdjacentText("afterbegin",`<div class='alert alert-error' role='alert'>${barcode_error}</div>`)
        }
    }
    pollForBarcodeData() {
        setTimeout(() => {
                this.getBarcodeData()
            },2000)
    }

    handleSuccess(barcodeData) {
        const barcode = barcodeData.barcode
        if(barcode){
            this.hideSpinner()
            this.generateBarcodeSvg(barcode)
            this.showBarcode(barcode)
        } else {
            this.pollForBarcodeData()
        }
    }
    handleError(errorData) {
        if (this.getBarcodeCount >= 10) {
            this.hideSpinner()
            const barcode_error = i18n.t('payment_methods.cash_voucher.error.barcode_error')
            document.querySelector('flash').insertAdjacentText("afterbegin",`<div class='alert alert-error' role='alert'>${barcode_error}</div>`)
        } else {
            this.pollForBarcodeData()
        }
    }
    showSpinner(){
        this.barcodeSpinnerTarget.removeAttribute('hidden')
    }

    hideSpinner(){
        this.barcodeSpinnerTarget.setAttribute('hidden', true)
    }

    generateBarcodeSvg(barcode) {
        this.barcodeSVGTarget.setAttribute('jsbarcode-value', barcode)
        JsBarcode('svg.barcode').init()
    }
    showBarcode(barcode) {
        this.barcodeBlockTarget.removeAttribute('hidden')
        this.barcodeValueTarget.insertAdjacentText("afterbegin", barcode)
    }
}
